import React, { useEffect, useState, useContext } from 'react';
import IdentityContext from '../auth/IdentityContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, FormControl, InputLabel, MenuItem, NativeSelect, Select, SelectChangeEvent } from '@mui/material';

import Price from '../widgets/shop/Price';
import API from '../../API';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import IfAuthorized from '../conditions/IfAuthorized';

function dateFromDay(year: number, day: number): Date {
  var date = new Date(year, 0); // initialize a date in `year-01-01`
  return new Date(date.setDate(day)); // add the number of days
}

/* Given a date, calculate the week number */
function getWeekNumber(date: Date) {
  const onejan = new Date(date.getFullYear(), 0, 1);
  return Math.ceil((((date.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
}

function getDaysData(day: number, year: string, data: Record<string, any>[]): string {
  if (!data) {
    return "";
  }
  const daysData = data.filter((dayData: any) => {
    const theDate = new Date(dayData.Date);
    return theDate.getDay() === day;
  });
  console.log(`getDaysData: ${JSON.stringify(daysData, null, 1)}`);

  if (!daysData || daysData.length === 0) {
    return "";
  }
  return (daysData[0][year]/100).toFixed(2);
}

function getWeeklyTotal(data: Record<string, any>[], year: string): string {
  if (!data) {
    return "";
  }
  const total = data.reduce((accumulator: number, currentValue: any) => {
    return accumulator + currentValue[year];
  }, 0);
  return (total/100).toFixed(2);
}

const AuditPage = () => {
  const {identity, setIdentity} = useContext(IdentityContext);
  const [weeklySummary, setWeeklySummary] = useState<any>({});
  const [dailySummary, setDailySummary] = useState<any>({});
  const [location, setLocation] = useState<string>("All");

  const now = new Date();

  const FIRST_YEAR = 2021;
  const LAST_YEAR = 2022;

  useEffect(() => {
    const doFetchTransactions = async() => {

      const theWeeklySummary: Record<number, any[]> = {};
      for (let year = FIRST_YEAR; year <= LAST_YEAR; year++) {
        const lastYearWeeklyTransactions = await API.getWeeklyTransactionSummary(location, year, identity?.accessToken);
        if (lastYearWeeklyTransactions) {
          theWeeklySummary[year] = lastYearWeeklyTransactions;
        }  
      }
      setWeeklySummary(theWeeklySummary);

      const theDailySummary: Record<number, any[]> = {};
      for (let year = FIRST_YEAR; year <= LAST_YEAR; year++) {
        const lastYearWeeklyTransactions = await API.getDailyTransactionSummary(location, year, identity?.accessToken);
        if (lastYearWeeklyTransactions) {
          theDailySummary[year] = lastYearWeeklyTransactions;
        }  
      }
      setDailySummary(theDailySummary);


    }

    doFetchTransactions();
  }, [location]);

  const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLocation(event.target.value as string);
  }

  // --------------------------------------------------------------------------------
  // Organize the weekly data into a format that the chart can use
  const weeklyChartData: Record<string, number>[] = [];
  for (let year = FIRST_YEAR; year <= LAST_YEAR; year++) {
    if (weeklySummary[year]) {
      weeklySummary[year].sort((a: any, b: any) => { return a._id - b._id } );
    }
  }

  for (let i = 0; i < 54; i++) {
    const week: Record<string, number> = {};
    week['Week'] = i + 1;

    for (let year = FIRST_YEAR; year <= LAST_YEAR; year++) {
      if (weeklySummary[year]) {
        const mySummary = weeklySummary[year];
        if (mySummary.length > i) {
          week[year] = mySummary[i].subtotal;
        }
      }
    }
    weeklyChartData.push(week);
  }
  // --------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------
  // Organize the daily data into a format that the chart can use
  const dailyChartData2021: Record<string, any>[] = [];
  for (let year = FIRST_YEAR; year <= FIRST_YEAR; year++) {
    if (dailySummary[year]) {
      dailySummary[year].sort((a: any, b: any) => { return a._id - b._id } );
    }
  }

  for (let i = 0; i < 366; i++) {
    const day: Record<string, any> = {};
    for (let year = FIRST_YEAR; year <= FIRST_YEAR; year++) {
      const theDate = dateFromDay(year, i);
      const weekNumber = getWeekNumber(theDate);
      day['Week'] = weekNumber;
      day['Day'] = i + 1;
      day['Date'] = theDate;

      if (dailySummary[year]) {
        const mySummary = dailySummary[year];
        if (mySummary.length > i) {
          day[year] = mySummary[i].subtotal;
        }
      }
    }
    dailyChartData2021.push(day);
  }
  // --------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------
  // Organize the daily data into a format that the chart can use
  const dailyChartData2022: Record<string, any>[] = [];
  for (let year = LAST_YEAR; year <= LAST_YEAR; year++) {
    if (dailySummary[year]) {
      dailySummary[year].sort((a: any, b: any) => { return a._id - b._id } );
    }
  }

  for (let i = 0; i < 366; i++) {
    const day: Record<string, any> = {};
    for (let year = LAST_YEAR; year <= LAST_YEAR; year++) {
      const theDate = dateFromDay(year, i);
      const weekNumber = getWeekNumber(theDate);
      day['Week'] = weekNumber;
      day['Day'] = i + 1;
      day['Date'] = theDate;

      if (dailySummary[year]) {
        const mySummary = dailySummary[year];
        if (mySummary.length > i) {
          day[year] = mySummary[i].subtotal;
        }
      }
    }
    dailyChartData2022.push(day);
  }

  // --------------------------------------------------------------------------------

  return (
    <div className="ags-main">
      <div className="ags-body">
        <FormControl fullWidth>
          <NativeSelect
            defaultValue="bridgewater"
            id="demo-simple-select"
            value={location}
            inputProps={{
              name: 'Location',
              id: 'locationSelector',
            }}
            onChange={handleLocationChange}
          >
            <option value="All">All</option>
            <IfAuthorized role="weed">
              <option value="bridgewater">Bridgewater</option>
              <option value="bongbus">Bong Bus</option>
              <option value="liverpool">Liverpool</option>
            </IfAuthorized>
            <IfAuthorized role='florist'>
              <option value="florist">Florist</option>
            </IfAuthorized>
          </NativeSelect>
        </FormControl>

        <Card>
          <h3>Weekly Sales, 2021/2022</h3>

          <TableContainer component={Paper}>
            <Table size="small" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Week</TableCell>
                  <TableCell>2021 Subtotal</TableCell>
                  <TableCell>2021 Total</TableCell>
                  <TableCell>2022 Subtotal</TableCell>
                  <TableCell>2022 Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  [...Array(54)].map((_, index) => {
                    const subtotal2021 = (weeklyChartData[index][2021] / 100).toFixed(2);
                    const subtotal2022 = (weeklyChartData[index][2022] / 100).toFixed(2);
                    const total2021 = (Math.round(weeklyChartData[index][2021] * 1.15) / 100).toFixed(2);
                    const total2022 = (Math.round(weeklyChartData[index][2022] * 1.15) / 100).toFixed(2);
                    return (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{subtotal2021}</TableCell>
                        <TableCell>{total2021}</TableCell>
                        <TableCell>{subtotal2022}</TableCell>
                        <TableCell>{total2022}</TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
          </Table>
          </TableContainer>
        </Card>


        <Card>
          <h3>2021 Daily Sales</h3>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Week</TableCell>
                  <TableCell>Sunday</TableCell>
                  <TableCell>Monday</TableCell>
                  <TableCell>Tuesday</TableCell>
                  <TableCell>Wednesday</TableCell>
                  <TableCell>Thursday</TableCell>
                  <TableCell>Friday</TableCell>
                  <TableCell>Saturday</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  [...Array(53)].map((_, index) => {
                    const theWeek = dailyChartData2021.filter((day) => day.Week === index + 1);

                    const sunday = getDaysData(0, "2021", theWeek);
                    const monday = getDaysData(1, "2021", theWeek);
                    const tuesday = getDaysData(2, "2021", theWeek);
                    const wednesday = getDaysData(3, "2021", theWeek);
                    const thursday = getDaysData(4, "2021", theWeek);
                    const friday = getDaysData(5, "2021", theWeek);
                    const saturday = getDaysData(6, "2021", theWeek);
                    const total = getWeeklyTotal(theWeek, "2021");
                    
                    return (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{sunday}</TableCell>
                        <TableCell>{monday}</TableCell>
                        <TableCell>{tuesday}</TableCell>
                        <TableCell>{wednesday}</TableCell>
                        <TableCell>{thursday}</TableCell>
                        <TableCell>{friday}</TableCell>
                        <TableCell>{saturday}</TableCell>
                        {/* <TableCell>{total}</TableCell> */}
                      </TableRow>
                    )
                  })
                }
              </TableBody>
          </Table>
          </TableContainer>
        </Card>

        <Card>
          <h3>2022 Daily Sales</h3>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Week</TableCell>
                  <TableCell>Sunday</TableCell>
                  <TableCell>Monday</TableCell>
                  <TableCell>Tuesday</TableCell>
                  <TableCell>Wednesday</TableCell>
                  <TableCell>Thursday</TableCell>
                  <TableCell>Friday</TableCell>
                  <TableCell>Saturday</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  [...Array(53)].map((_, index) => {
                    const theWeek = dailyChartData2022.filter((day) => day.Week === index + 1);

                    const sunday = getDaysData(0, "2022", theWeek);
                    const monday = getDaysData(1, "2022", theWeek);
                    const tuesday = getDaysData(2, "2022", theWeek);
                    const wednesday = getDaysData(3, "2022", theWeek);
                    const thursday = getDaysData(4, "2022", theWeek);
                    const friday = getDaysData(5, "2022", theWeek);
                    const saturday = getDaysData(6, "2022", theWeek);
                    const total = getWeeklyTotal(theWeek, "2022");
                    
                    return (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{sunday}</TableCell>
                        <TableCell>{monday}</TableCell>
                        <TableCell>{tuesday}</TableCell>
                        <TableCell>{wednesday}</TableCell>
                        <TableCell>{thursday}</TableCell>
                        <TableCell>{friday}</TableCell>
                        <TableCell>{saturday}</TableCell>
                        {/* <TableCell>{total}</TableCell> */}
                      </TableRow>
                    )
                  })
                }
              </TableBody>
          </Table>
          </TableContainer>
        </Card>

      </div>
    </div>
  )
}

export default AuditPage